import React from 'react';

const Blog = () => {
  return (
    <div>
      <h1>Blog</h1>
      <p>This is the blog page.</p>
    </div>
  );
};

export default Blog;
