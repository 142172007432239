import React from 'react';
import LandingAboutSection from './LandingAboutSection';
import LandingBlogSection from './LandingBlogSection';
import LandingPortfolioSection from './LandingPortfolioSection';
import LandingContactSection from './LandingContactSection';


const LandingPage = () => {
  return (
    <div>
      <LandingAboutSection />
      <LandingBlogSection />
      <LandingPortfolioSection />
      <LandingContactSection />
      {/* You can add more sections here in the future */}
    </div>
  );
};

export default LandingPage;
