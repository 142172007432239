import React from 'react';
import { Link } from 'react-router-dom';
import 'typeface-roboto';

const LandingBlogSection = () => {
  return (
    <div className="bg-gray-50 py-10 px-4" style={{ fontFamily: 'Roboto, sans-serif' }}>
      <div className="container mx-auto text-center">
        <h2 className="text-2xl font-bold mb-4">Latest Blog Posts</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="bg-white p-4 shadow-md">
            <img src="https://placehold.co/400x300" alt="Blog Post" className="w-full mb-4" />
            <h3 className="text-xl font-semibold mb-2">Blog Post Title 1</h3>
            <p className="text-gray-600">This is a short description of the blog post.</p>
          </div>
          <div className="bg-white p-4 shadow-md">
            <img src="https://placehold.co/400x300" alt="Blog Post" className="w-full mb-4" />
            <h3 className="text-xl font-semibold mb-2">Blog Post Title 2</h3>
            <p className="text-gray-600">This is a short description of the blog post.</p>
          </div>
          <div className="bg-white p-4 shadow-md">
            <img src="https://placehold.co/400x300" alt="Blog Post" className="w-full mb-4" />
            <h3 className="text-xl font-semibold mb-2">Blog Post Title 3</h3>
            <p className="text-gray-600">This is a short description of the blog post.</p>
          </div>
        </div>
        <Link to="/blog">
          <button className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600">View All Blog Posts</button>
        </Link>
      </div>
    </div>
  );
};

export default LandingBlogSection;
