import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'typeface-montserrat';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-green-700 text-white py-4 px-4" style={{ fontFamily: "'Montserrat', sans-serif" }}>
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-8xl" style={{ fontFamily: "'Fredericka the Great', cursive" }}>
          <Link to="/">Shahab.dev</Link>
        </div>

        <div className="hidden md:flex space-x-8 text-lg">
          <Link to="/about" className="hover:text-gray-300 transition duration-300">About</Link>
          <Link to="/blog" className="hover:text-gray-300 transition duration-300">Blog</Link>
          <Link to="/music" className="hover:text-gray-300 transition duration-300">Music</Link>
          <Link to="/contact" className="hover:text-gray-300 transition duration-300">Contact</Link>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden px-4 text-lg">
          <Link to="/about" className="block py-2 hover:bg-green-600 transition duration-300" onClick={toggleMenu}>About</Link>
          <Link to="/blog" className="block py-2 hover:bg-green-600 transition duration-300" onClick={toggleMenu}>Blog</Link>
          <Link to="/music" className="block py-2 hover:bg-green-600 transition duration-300" onClick={toggleMenu}>Music</Link>
          <Link to="/contact" className="block py-2 hover:bg-green-600 transition duration-300" onClick={toggleMenu}>Contact</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
