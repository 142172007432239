import React from 'react';

const LandingPortfolioSection = () => {
  return (
    <div className="bg-white py-10 px-4">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl font-bold mb-4">Specializing in Lettering, Murals, and Food Art for Creative Clients</h2>
        <div className="flex justify-center space-x-4 mb-8">
          {/* Replace with actual logos */}
          <img src="https://placehold.co/100x50" alt="Client Logo" className="h-8" />
          <img src="https://placehold.co/100x50" alt="Client Logo" className="h-8" />
          <img src="https://placehold.co/100x50" alt="Client Logo" className="h-8" />
          <img src="https://placehold.co/100x50" alt="Client Logo" className="h-8" />
          <img src="https://placehold.co/100x50" alt="Client Logo" className="h-8" />
          <img src="https://placehold.co/100x50" alt="Client Logo" className="h-8" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <img src="https://placehold.co/400x300" alt="Portfolio Item" className="w-full" />
          <img src="https://placehold.co/400x300" alt="Portfolio Item" className="w-full" />
          <img src="https://placehold.co/400x300" alt="Portfolio Item" className="w-full" />
          <img src="https://placehold.co/400x300" alt="Portfolio Item" className="w-full" />
          <img src="https://placehold.co/400x300" alt="Portfolio Item" className="w-full" />
          <img src="https://placehold.co/400x300" alt="Portfolio Item" className="w-full" />
          <img src="https://placehold.co/400x300" alt="Portfolio Item" className="w-full" />
          <img src="https://placehold.co/400x300" alt="Portfolio Item" className="w-full" />
        </div>
        <button className="mt-8 bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600">View My Entire Portfolio</button>
      </div>
    </div>
  );
};

export default LandingPortfolioSection;
