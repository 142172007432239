import React from 'react';
import { Link } from 'react-router-dom';

const LandingContactSection = () => {
  return (
    <div className="bg-gray-200 py-10 px-4 text-center">
      <div className="container mx-auto">
        <Link to="/contact">
          <button className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600">
            Contact Me
          </button>
        </Link>
      </div>
    </div>
  );
};

export default LandingContactSection;
